<template>
	<div class="content">
		
		<div>
			<el-form ref="imgForm" v-loading="imgLoading" v-show="!videoMode">
				<el-form-item label="图片关键词" style="margin-bottom: .4rem">
					<el-input v-model="imgForm.keywords" placeholder="请描述要成生成的信息" el-input></el-input>
				</el-form-item>
				<el-form-item label="自动翻译 (如果英文不好请勿修改)">
					<el-input v-model="imgForm.en_keywords" placeholder="图片生成需要英文" el-input></el-input>
				</el-form-item>
				
				<el-button size="medium" type="primary" @click="genImg()">一键生成图片</el-button>
			</el-form>
			
			<div class="result">
				<h3>素材结果:</h3>
        <div>
          <img class="img-result" :src="require('@/assets/img/default.jpg')" v-show="!textForm.imgUrl && textForm.selectLocalImgList.length === 0"/>
        </div>
        <div class="ing-result-box">
          <div>
            <img class="img-result" :src="textForm.imgUrl" v-show="textForm.imgUrl"/>
          </div>

          <div v-if="isVideo(video)" v-for="video in textForm.selectLocalImgList" :key="video">
            <video class="img-result" style="width: 300px;height: 400px" controls>
              <source :src="video">
            </video>
          </div>

          <img v-if="isImage(img)" class="img-result-local" v-for="img in textForm.selectLocalImgList" :src="img" :key="img"/>

        </div>
        <div>
          <el-button size="medium" @click="selectLocalImg()">本地素材</el-button>
        </div>
			</div>
		</div>
		
		<div>
			<el-form ref="form" v-loading="textLoading">
        <div style="height: 7rem" v-show="videoMode"></div>
				<el-form-item class="search-item" label="标题">
					<el-input v-model="textForm.title"></el-input>
				</el-form-item>
				<el-form-item label="文章关键词" v-show="!videoMode">
					<el-input v-model="textForm.keyword" placeholder="请描述要成生成的信息" el-input type="textarea" style="height: 5rem"></el-input>
				</el-form-item>
				
				<el-form-item class="search-item" label="字数" v-show="!videoMode">
					<el-input v-model="textForm.wordCount" placeholder="400" class="phone"></el-input>
				</el-form-item>
				
				<el-button size="medium" type="primary" @click="genText()" v-show="!videoMode">一键生成文章</el-button>
			</el-form>
			<div class="result" v-show="!videoMode">
				<h3>文本结果: <i style="font-size: 12px">(可手动修改)</i></h3>
				<el-input v-show="textForm.text.length>1" type="textarea" autosize class="article-content" v-model="textForm.text"></el-input>
			</div>
			
			
		</div>
		<div class="bottom-box">
			<div>自动发布时间:
				<el-date-picker
					v-model="textForm.releaseDate"
					type="date"
					range-separator="至"
					value-format="yyyy-MM-dd"
					placeholder="日期"
					style="width: 155px;padding: 0 10px;"
				/>
				<el-time-picker
					v-model="textForm.releaseDateTime"
					type="dates"
					range-separator="至"
					value-format="yyyy-MM-dd HH:mm:ss"
					placeholder="时间"
					style="width: 150px;padding: 0 10px;"
				/>
			</div>

      <driver-select style="margin-top:20px" @change="driverChange" :selectDriverIn="textForm.driverList" v-show="textForm.releaseDateTime"></driver-select>

			<div style="margin-top: 3rem;margin-bottom: 6rem">
				<el-button size="medium" type="primary" @click="saveArticle()">全部保存</el-button>
			</div>
		</div>
		<div>
      <el-dialog
        class="img-list-view"
        :visible.sync="imgListVisible"
        :fullscreen="true"
        @close="imgListClose"
        :close-on-click-modal="false">
        <ImgListView :isSelectMode="true" @selectDone="selectDone"/>
      </el-dialog>
    </div>
	</div>
</template>

<script>

import ImgListView from '@/page/client/material/list.vue';
import DriverSelect from "@/components/driverSelect.vue";
import {isImage, isVideo} from "@/utils/utils";

export default {
	data() {
		return {

      imgListVisible: false,

			total: 0,
			otherMap: {totalAccount: 0},
			textForm: {
				id:'',
				title: "",
				keyword:"",
				wordCount: 400,
				releaseDate: "",
				releaseDateTime: "",
				
				imgUrl: '',
				imgUrlOrigin:"",
				text:"",
        selectLocalImgList: [],
        driverList: [],
			},
      videoMode: false,
			imgForm:{
				keywords: '',
				en_keywords: '',
			},
			imgLoading: false,
			textLoading: false,
		};
	},
	
	components: {DriverSelect, ImgListView},
	
	computed: {},
	
	mounted() {
		if (this.$route.query.id) {
			this.textForm.id = this.$route.query.id
			this.initEdit()
		} else {
			this.initDraft()
		}
	},
	
	watch: {
		//观察 this.form.keywords
		'imgForm.keywords': {
			handler: function (val) {
				// if (val.length > 0) {
					// this.imgForm.en_keywords = ''
				// }
			},
			deep: true,
		},
	},
	
	methods: {
		initDraft() {
			//检查是否存在草稿
			this.$http.post('/client/gen/getArticleList', {status:'draft', pageNum:0, pageSize:1, fullContent:true}).then(res => {
				if (res.data.list[0]) {
					let article = res.data.list[0]
					this.initArticleData(article)
				}
			})
		},
		initEdit() {
			//准备编辑数据
			this.textForm.id = parseInt(this.$route.query.id)
			this.$http.post('/client/gen/getArticleList', {id: parseInt(this.$route.query.id), pageNum:0, pageSize:1, fullContent:true}).then(res => {
				if (res.data.list[0]) {
					let article = res.data.list[0]
					this.initArticleData(article)
				}
			})
		},
		initArticleData(article) {
			this.textForm.id = article._id
			this.textForm.title = article.title
			this.textForm.keyword = article.keyword
      this.textForm.selectLocalImgList = article?.selectLocalImgList || []
			this.imgForm.keywords = article.keywords
			this.imgForm.en_keywords = article.en_keywords
      this.textForm.driverList = article.driverList ?? []
			
			this.textForm.text = article.content
			if (article.imgUrl && article.imgUrl !== '$empty') {
				this.textForm.imgUrlOrigin = article.imgUrl
				this.textForm.imgUrl = process.env.VUE_APP_BASE_API + article.imgUrl
			}
			if (!this.textForm.text && !this.isVideo(this.textForm?.selectLocalImgList[0])) {
				this.beginLoadingArticle()
			}
			if (article.releaseDate) {
				let releaseData = new Date(article.releaseDate.date.replace(" ", "T"))
				this.textForm.releaseDate = releaseData.getFullYear() + '-' + (releaseData.getMonth() + 1) + '-' + releaseData.getDate()
				this.textForm.releaseDateTime = this.textForm.releaseDate + ' ' +  releaseData.getHours() + ':' + releaseData.getMinutes() + ':' + releaseData.getSeconds()
			}

      if (this.textForm.selectLocalImgList.length > 0) {
        let imgPrefix = this.textForm.selectLocalImgList[0].split(',')[0]
        pywebview.api.getFilePrefix(imgPrefix).then(res => {
          this.textForm.selectLocalImgList.forEach((item, key) => {
            let tail = item.split(',')[1]
            this.textForm.selectLocalImgList[key] = res + tail
          })
          this.$forceUpdate()
        })

        //是否是视频模式
        this.videoMode = false
        this.textForm.selectLocalImgList.forEach((item) => {
          if (this.isVideo(item)) {
            this.videoMode = true
          }
        })
      }
		},
		genText() {

      if (this.isVideo(this.textForm.selectLocalImgList[0])) {
        this.$message({message: "视频素材无法生成图片",});
        return
      }

			//必须输入标题
			if (this.textForm.title === '') {
				this.$message({message: "请输入标题",});
				return
			}
			//标题最少8个字
			if (this.textForm.title.length < 8) {
				this.$message({message: "标题最少8个字",});
				return
			}
			if (this.textForm.keyword === '') {
				this.$message({message: "请输入关键词",});
				return
			}
			if (this.textForm.wordCount <= 10) {
				this.$message({message: "请输入字数",});
				return
			}
			//不允许大于1000字
			if (this.textForm.wordCount > 1000) {
				this.$message({message: "字数不能大于1000",});
				return
			}
			this.textForm.text = ''
			let postData = JSON.parse(JSON.stringify(this.textForm))
			postData.imgUrl = this.textForm.imgUrlOrigin
			return this.$http.post('/client/gen/genText', postData).then(res => {
        console.log('新文章成品ID:', res.data.id)
				this.textForm.id = res.data.id
				this.beginLoadingArticle()
			})
		},
		beginLoadingArticle() {
			this.textLoading = true
			let internal = setInterval(() => {
				this.$http.post('/client/gen/getArticleList', {id: this.textForm.id, pageNum:0, pageSize:1}).then(res => {
					if (res.data.list[0] && res.data.list[0].content) {
						this.textForm.text = res.data.list[0].content
						this.textLoading = false
						clearInterval(internal)
					}
				})
			}, 7000)
		},
		genImg() {
			if (this.imgForm.keywords === '' && this.imgForm.en_keywords === '') {
				this.$message({message: "请输入关键词",});
				return
			}
      if (this.isVideo(this.textForm.selectLocalImgList[0])) {
        this.$message({message: "视频素材无法生成图片",});
        return
      }
			let keywords = this.imgForm.keywords
			if (this.imgForm.en_keywords) {
				keywords = this.imgForm.en_keywords
			}
			this.imgLoading = true
			this.genImgFact(keywords)
		},
		genImgFact(keywords) {
			this.$http.post('/client/gen/genImg', {
				text: keywords,
				id: this.textForm.id ? this.textForm.id : 0,
			}).then(res => {
				this.textForm.imgUrlOrigin = res.data.url
				this.textForm.imgUrl = process.env.VUE_APP_BASE_API + res.data.url
				this.imgForm.en_keywords = res.data.en_keywords
        this.textForm.id = res.data.id
			}).finally(() => {
				this.imgLoading = false
			})
		},
    selectLocalImg() {
      this.imgListVisible = true
    },
		async saveArticle(silent = false) {
      if (this.textForm.driverList.length === 0) {
        this.$message({message: "请选择发布平台和账户",});
        return
      }
      //必须输入标题
      if (this.textForm.title === '') {
        this.$message({message: "请输入标题",});
        return
      }
      //标题最少8个字
      if (this.textForm.title.length < 8) {
        this.$message({message: "标题最少8个字",});
        return
      }

      let postData = {...this.textForm}

      console.log('保存文章', postData)

      let releaseData = new Date(this.textForm.releaseDate)
      let hourDate = new Date(this.textForm.releaseDateTime)
      //合并日期和时间
      releaseData.setHours(hourDate.getHours())
      releaseData.setMinutes(hourDate.getMinutes())
      releaseData.setSeconds(hourDate.getSeconds())
      postData.releaseDate = releaseData ? releaseData : ''
      postData.isDraft = silent

      for (const item of postData.selectLocalImgList) {
        if (item.indexOf('http') !== 0) {
          continue
        }
        const index = postData.selectLocalImgList.indexOf(item);
        //取出连接的域名加端口
        let port = item.split('/').slice(0, 3).join('/').split(':').slice(-1)[0]
        let tail = item.split(':').slice(2).join('').split('/').slice(1).join('/')
        console.log('端口转目录前缀', port, tail)
        await pywebview.api.getInputPath(port).then(res => {
          postData.selectLocalImgList[index] = res + ',/' + tail
        })
      }

      this.$http.post('/client/gen/saveArticle', postData).then(() => {

        silent || this.$message({
          message: "保存成功, 可在发布管理中执行发布",
          type: 'success'
        });
      })
    },
    imgListClose() {
      // 这里是你的处理逻辑
      console.log('关闭', this.imgListVisible);
    },
    selectDone(imgList) {
      this.textForm.selectLocalImgList = imgList
      this.imgListVisible = false
      this.saveArticle(true)

      this.videoMode = false
      imgList.forEach((item) => {
        if (this.isVideo(item)) {
          this.videoMode = true
        }
      })
    },
    driverChange(driverList) {
      this.textForm.driverList = driverList
    },
    isImage(str) {
      return isImage(str);
    },
    isVideo(str) {
      return isVideo(str);
    },
	},
};
</script>
<style lang="scss" scoped>
.el-form-item {
	flex-wrap: nowrap;
}

.content {
	display: flex;
	flex-direction: column;
	.bottom-box {
		display: flex;
		justify-content: center;
		width: 100%;
		border-top: solid 1px #eee;
		flex-direction: column;
		text-align: center;
	}
}

//大于700px 一行显示2个
@media screen and (min-width: 700px) {
	.content {
		flex-direction: row;
		flex-wrap: wrap;
		
		> div {
			width: 50%;
			padding: 1rem;
		}
	}
	.img-result {
		width: 50%;
		margin: 0 auto;
	}
  .ing-result-box {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 5px 0 10px;
  }
  .img-result-local {
    width: 30%;
    height: 30%;
    margin: 5px 5px;
    border-radius: 5px;
  }
}
.img-result {
	width: 70%;
	margin: 0 auto;
}
.result {
	margin-top: 1rem;
	
	img {
		max-width: 100%;
	}
}

.otherContent {
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
	flex-wrap: wrap;
	
	.tableAction {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-right: 5px;
		
		.el-button {
			margin-bottom: 5px;
		}
	}
	
	.allAccount {
		display: grid;
		place-items: end;
	}
}
.img-list-view {
  width: 90%;
  height: 90%;
  border-radius: 10px;
  margin: 5% auto 0;
}

::v-deep .el-message-box {
	max-width: 100% !important;
}

.el-message-box__message {
	max-width: 100% !important;
}

::v-deep .el-textarea__inner {
	height: 5rem;
}
</style>
<style>
.el-message-box {
	max-width: 100% !important;
}
</style>